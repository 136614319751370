import * as React from "react"
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import {Helmet} from "react-helmet";
import Footer from "../components/footer";
import Header from "../components/header";

const InfoPage = ({data}) => {

    const partners = data.allContentfulPartners.nodes;
    const documents = data.allContentfulDocument.nodes
    const pages = data.allContentfulPages.nodes
    const teams = data.allContentfulTeam.nodes

    return (
        <Layout>
            <Helmet>
                <title>Információk - Zempléni Hiúzok</title>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                <section className="section section-md bg-gray-100">
                    <div className="container">
                        <div className="row row-50">
                            <div className="col-lg-8">
                                    <div className="main-component">
                                        <article className="heading-component">
                                            <div className="heading-component-inner">
                                                <h5 className="heading-component-title">Letölthető dokumentumok</h5>
                                            </div>
                                        </article>

                                        <div className={'blog-post'}>
                                            <dl>
                                                {documents.map(function(document,index){
                                                    return <a href={document.file.url} target={'_blank'} rel={'noreferrer'} download>
                                                        <dd className={'mb-3 text-truncate'}>
                                                            <span className="icon icon-primary mdi mdi-file-pdf-box me-3 "/>
                                                            {document.title}
                                                        </dd>
                                                    </a>
                                                })}
                                            </dl>
                                        </div>
                                    </div>
                            </div>
                            <div className={'col-lg-4'}>
                                <article className="heading-component">
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">További információk</h5>
                                    </div>
                                </article>
                                {pages.map(function(page,index){
                                    return <article key={index} className="post-classic">
                                        <div className="post-classic-main">
                                            <p className="post-classic-title">
                                                <Link to={'/informaciok/'+page.slug}>{page.title}</Link>
                                            </p>
                                        </div>
                                    </article>
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer partners={partners}/>
        </Layout>
    )
}

export default InfoPage

export const query = graphql`
  query getDocuments {
  allContentfulDocument {
    nodes {
      file {
        url
      }
      title
    }
  }
  allContentfulPartners {
    nodes {
      title
      logo {
        url
      }
    }
  }
  allContentfulPages {
    nodes {
      text {
        raw
      }
      title
      slug
    }
  }
  allContentfulTeam(sort: {fields: title, order: ASC}) {
    nodes {
      title
      label
    }
  }
}
`
